// actions.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { favoriteFolders } from "../../utils/types";
import {
    httpSendFolder,
    httpUpdateFolder,
    httpDeleteFolder,
    httpGetFolders,
} from "./api";

export const getAllFavoriteFolders = createAsyncThunk(
    "folders/getAllFavoriteFolders",
    async () => {
        const data = await httpGetFolders();
        return data; // array of folders
    }
);

// createFolder thunk action
export const createFolder = createAsyncThunk<
    favoriteFolders,
    { folderName: string },
    { rejectValue: string }
>("favoriteFolders/send", async (folders, thunkAPI) => {
    try {
        const newFolder = await httpSendFolder(folders);
        return newFolder;
    } catch (err) {
        return thunkAPI.rejectWithValue("Failed to create folder");
    }
});

// updateFolder thunk action
export const updateFolder = createAsyncThunk<
    favoriteFolders,
    { id: number; folderName: string },
    { rejectValue: string }
>("favoriteFolders/update", async (folder, thunkAPI) => {
    try {
        return await httpUpdateFolder(folder);
    } catch (err) {
        return thunkAPI.rejectWithValue("Failed to update folder");
    }
});

// deleteFolder thunk action
export const deleteFolder = createAsyncThunk<
    { id: number },
    number,
    { rejectValue: string }
>("favoriteFolders/delete", async (id, thunkAPI) => {
    try {
        await httpDeleteFolder(id);
        return { id };
    } catch (err) {
        return thunkAPI.rejectWithValue("Failed to delete folder");
    }
});