import React, { useState, MouseEvent, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/utils/types";
import { RxCross2 as Cross } from "react-icons/rx";
import { Link } from "react-router-dom";
import NotificationToast from "../../../components/NotificationToast";
import { authSelector } from "../../../redux/modules/auth/userSelector";
import { getAllFavoriteFolders } from "../../../redux/modules/favorites/action";
import { favoriteFoldersCompanySelector } from "../../../redux/modules/favoritesCompany/selectors";
import { addCompanyInFolderFavoriteFoldersCompany, getFavoriteFoldersIdCompany, removeCompanyInFolder } from "../../../redux/modules/favoritesCompany/action";

export default function FavoritesFolderDropdown(props: {
    favoriteFolders: { id: number; folderName: string; companyName: string }[];
    handleDragOver: (e: React.DragEvent) => void;
    handleShow: (folder: string | null) => void;
    removeFavoriteFolder: (folderName: string) => void;
}) {
    const {
        favoriteFolders,
        handleDragOver,
        handleShow,
        removeFavoriteFolder,
    } = props;

    const dispatch = useDispatch<any>();
    const [selectedFolder, setSelectedFolder] = useState<string | null>(null);
    const [menuOpen, setMenuOpen] = useState<string | null>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [notification, setNotification] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
    const { user } = useSelector(authSelector);

    useEffect(() => {
        if (dropdownOpen) {
            dispatch(getAllFavoriteFolders());
        }
    }, [dropdownOpen, dispatch]);

    useEffect(() => {
        if (selectedFolder) {
            const folderId = favoriteFolders.find(
                (folder) => folder.folderName === selectedFolder
            )?.id;
            if (folderId) {
                dispatch(getFavoriteFoldersIdCompany(folderId));
            }
        }
    }, [selectedFolder, dispatch, favoriteFolders]);

    const selectedFolderId = favoriteFolders.find(
        (folder) => folder.folderName === selectedFolder
    )?.id;

    const companies = useSelector((state: RootState) => {
        const favoriteFoldersCompanyState = state.favoriteFoldersCompany || {};
        return favoriteFoldersCompanyState.companies?.[selectedFolderId ?? -1] || [];
    });

    const { status, message } = useSelector(favoriteFoldersCompanySelector);

    useEffect(() => {
        if (status === 'success' || status === 'failed') {
           // setNotification({ show: true, message: message || '' });
            const timer = setTimeout(() => {
                setNotification({ show: false, message: '' });
                dispatch({ type: 'favoriteFoldersCompany/reset' }); // Ensure to reset the status and message
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [status, message, dispatch]);

    const toggleDropdown = useCallback(
        (e: MouseEvent) => {
            e.preventDefault();
            setDropdownOpen((prev) => !prev);
        },
        []
    );

    const handleFolderClick = useCallback((folder: string) => {
        setSelectedFolder((prev) => (prev === folder ? null : folder));
    }, []);

    const handleMenuToggle = useCallback((e: MouseEvent, folder: string) => {
        e.stopPropagation();
        setMenuOpen((prev) => (prev === folder ? null : folder));
    }, []);

    const handleCompanyClick = useCallback((e: MouseEvent) => {
        e.stopPropagation();
    }, []);

    const handleRemoveCompany = useCallback(
        (folderId: number, companyId: number, e: React.MouseEvent) => {
            e.stopPropagation();
            dispatch(removeCompanyInFolder({ folderId, companyId }))
                .unwrap()
                .then(() => {
                    if (selectedFolder) {
                        const selectedFolderId = favoriteFolders.find(
                            (folder) => folder.folderName === selectedFolder
                        )?.id;
                        if (selectedFolderId) {
                            dispatch(getFavoriteFoldersIdCompany(selectedFolderId));
                        }
                    }
                    setNotification({ show: true, message: 'Company removed successfully' });
                    setTimeout(() => setNotification({ show: false, message: '' }), 3000);
                })
                .catch((error: any) => {
                    console.error("Failed to remove company:", error);
                    setNotification({ show: true, message: 'Failed to remove company' });
                    setTimeout(() => setNotification({ show: false, message: '' }), 3000);
                });
        },
        [dispatch, favoriteFolders, selectedFolder]
    );

    const handleDrop = async (e: React.DragEvent, folder: string) => {
        e.preventDefault();
        const companyName = e.dataTransfer.getData("text/plain");

        const folderObj = favoriteFolders.find((f) => f.folderName === folder);
        if (folderObj) {
            const folderId = folderObj.id;
            try {
                await dispatch(
                    addCompanyInFolderFavoriteFoldersCompany({ folderId, companyName })
                ).unwrap();
                dispatch(getAllFavoriteFolders());
                setNotification({ show: true, message: 'Company added successfully' });
                setTimeout(() => setNotification({ show: false, message: '' }), 3000);
            } catch (error) {
                console.error("Failed to add company to folder:", error);
                setNotification({ show: true, message: 'Failed to add company' });
                setTimeout(() => setNotification({ show: false, message: '' }), 3000);
            }
        } else {
            console.error("Folder not found");
        }

        setDropdownOpen(true);
    };

    // Filter folders based on companyName
    const filteredFolders = favoriteFolders.filter(folder => folder.companyName === user?.companyName);

    return (
        <>
            <li className="nav-item dropdown">
                <a
                    href="#!"
                    onClick={toggleDropdown}
                    className="nav-link text-dark dropdown-toggle"
                    aria-expanded={dropdownOpen}
                >
                    <i className="fa fa-folder mr-3 fa-fw text-dark"></i>
                    Favorites Folder
                </a>
                <ul
                    className={`dropdown-menu ${
                        dropdownOpen ? "show" : ""
                    } w-100 p-1 bg-light rounded border shadow-sm`}
                >
                    {filteredFolders.length > 0 ? (
                        filteredFolders.map((folder) => (
                            <li
                                key={folder.id}
                                className="nav-item d-flex flex-column p-2 mb-2 bg-white border rounded shadow-sm"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, folder.folderName)}
                                style={{ height: "auto", cursor: "pointer" }}
                                onClick={() => handleFolderClick(folder.folderName)}
                            >
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <span
                                        className={
                                            "nav-link text-dark bg-light rounded px-4 py-2 d-flex align-items-center folder-name-font" +
                                            (selectedFolder === folder.folderName
                                                ? " text-white bg-primary"
                                                : "")
                                        }
                                    >
                                        <span className="me-2">{folder.folderName}</span>
                                    </span>
                                    <div
                                        onClick={(e) => e.stopPropagation()}
                                        className="position-relative"
                                    >
                                        <button
                                            className="btn btn-sm btn-light"
                                            onClick={(e) => handleMenuToggle(e, folder.folderName)}
                                        >
                                            &#8942;
                                        </button>
                                        {menuOpen === folder.folderName && (
                                            <div className="dropdown-menu dropdown-menu-end show position-absolute action-popup">
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        handleShow(folder.folderName);
                                                        setMenuOpen(null);
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className="dropdown-item"
                                                    onClick={() => {
                                                        removeFavoriteFolder(folder.folderName);
                                                        setMenuOpen(null);
                                                    }}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {selectedFolder === folder.folderName && companies.length > 0 ? (
                                    <ul className="list-group sh">
                                        {companies.map((company) => (    
                                            <li
                                                key={company.companyId}
                                                className="d-flex justify-content-between align-items-center border rounded py-1 px-1 company-favorite-links-container"
                                            >
                                                <Link
                                                    to={`/home/view/${company.companyId}`}
                                                    onClick={handleCompanyClick}
                                                    className="text-primary company-name-size"
                                                >
                                                    {company.companyName}
                                                </Link>
                                                <Cross
                                                    className="text-danger h6 cross-btn-company"
                                                    role="button"
                                                    onClick={(e) => handleRemoveCompany(folder.id, company.companyId, e)}
                                                    title="Delete Company"
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    selectedFolder === folder.folderName && (
                                        <p className="text-muted mb-0 ml-4">No companies available</p>
                                    )
                                )}
                            </li>
                        ))
                    ) : (
                        <li className="nav-item text-center">
                            <p className="text-muted mb-0">No folders available</p>
                        </li>
                    )}
                </ul>
            </li>
            {notification.show && (
                <NotificationToast message={notification.message} show={notification.show} />
            )}
        </>
    );
}