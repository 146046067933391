// api.ts
import axios from "../../../adapters/axios/";

// Fetch all folders company
// export const httpGetFolderCompanies = async () => {
//     const response = await axios.get('folder-companies');
//     return response.data;
//   };

// fetch companyName with specific folder   folder-companies/by-folder/{folderId}
export const httpGetFolderIdCompanies = async (folderId: number) => {
  const response = await axios.get(`/folder-companies/by-folder/${folderId}`);
  return response.data;
};

// add a new company inside folder  /folders/{folderId}/companyId
export const httpAddFolderCompany = async (
  folderId: number,
  data: { companyName: string }
) => {
  const response = await axios.post(`/folders/${folderId}/companyId`, data);
  return response.data;
};

// remove a company from a folder
export const httpRemoveFolderCompany = async (
  folderId: number,
  companyId: number
) => {
  const response = await axios.delete(`/folders/${folderId}/companies/${companyId}`);
  return response.data;
};
