// slice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { favoriteFoldersCompany, RootState } from "../../utils/types";
import {
  addCompanyInFolderFavoriteFoldersCompany,
  getFavoriteFoldersIdCompany,
  removeCompanyInFolder,
} from "./action";

const initialState: RootState["favoriteFoldersCompany"] = {
  status: "idle",
  message: "",
  map: new Map(),
  companies: {},
};

export const FavoriteFoldersCompany = createSlice({
  name: "foldersCompany",
  initialState,
  reducers: {
    setFolder: (state, action: PayloadAction<favoriteFoldersCompany>) => {
      state.map.set(action.payload.id.toString(), action.payload);
    },
    reset: (state) => {
      state.status = "idle";
      state.message = "";
    },
    updateCompanies: (state, action: PayloadAction<{ folderId: string; updatedCompanies: any[] }>) => {
      const { folderId, updatedCompanies } = action.payload;
      state.companies[folderId] = updatedCompanies;
    },
  },
  extraReducers: (builder) => {
    builder

      // Handle get company by folder id
      .addCase(getFavoriteFoldersIdCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFavoriteFoldersIdCompany.fulfilled, (state, action) => {
        state.status = "success";
        // action.payload is an array of company data
        state.companies[action.meta.arg.toString()] = action.payload;
        state.status = "idle";
      })
      .addCase(getFavoriteFoldersIdCompany.rejected, (state) => {
        state.status = "failed";
      })

       // Handle add company inside folder
      .addCase(addCompanyInFolderFavoriteFoldersCompany.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        addCompanyInFolderFavoriteFoldersCompany.fulfilled,
        (state, action) => {
          //const { folderId, companyId, companyName } = action.payload;
          const { folderId} = action.payload;
          const folder = state.map.get(folderId);
          if (folder) {
            // companyId logic
          }
          state.status = "success";
          state.message = "Company Added Successfully";
        }
      )
      .addCase(addCompanyInFolderFavoriteFoldersCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload as string || 'Failed to add company';
      })

      // Handle remove company from the folder
      .addCase(removeCompanyInFolder.pending, (state) => {
        state.status = "loading";
      })

      .addCase(removeCompanyInFolder.fulfilled, (state, action) => {
        const { folderId, companyId } = action.meta.arg;
        // Remove company from the state
        state.companies[folderId] = state.companies[folderId].filter(
          (company) => company.companyId !== companyId
        );
        state.status = 'success';
        state.message = 'Company removed successfully';
      })
      .addCase(removeCompanyInFolder.rejected, (state, action) => {
        state.status = "failed";
        state.message = action.payload as string || "Failed to delete the company";
      });
  },
});

export default FavoriteFoldersCompany.reducer;
export const { setFolder, reset, updateCompanies } = FavoriteFoldersCompany.actions;
