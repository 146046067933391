// actions.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  httpAddFolderCompany,
  httpGetFolderIdCompanies,
  httpRemoveFolderCompany,
} from "./api";

//  get companyName with specific folder  thunk action
export const getFavoriteFoldersIdCompany = createAsyncThunk(
  "folders/getAllFavoriteFoldersCompany",
  async (id: number) => {
    const data = await httpGetFolderIdCompanies(id);
    console.log("dataaa", data);
    return data; //  the array of folders
  }
);

// add company thunk action
export const addCompanyInFolderFavoriteFoldersCompany = createAsyncThunk(
  "favoriteFolders/addCompanyInFolder",
  async (
    { folderId, companyName }: { folderId: number; companyName: string },
    thunkAPI
  ) => {
    try {
      const response = await httpAddFolderCompany(folderId, { companyName });
      return response; // You can return the response if needed
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);

// delete company thunk action
export const removeCompanyInFolder = createAsyncThunk(
  "favoritesCompany/deleteCompanyFromFolder",
  async (data: { folderId: number; companyId: number }, thunkAPI) => {
    try {
      const response = await httpRemoveFolderCompany(
        data.folderId,
        data.companyId
      );
      return response;
    } catch (err:any) {
      return thunkAPI.rejectWithValue(err.response?.data || err.message);
    }
  }
);