// api.ts
import axios from "../../../adapters/axios/";

// Fetch all folders
export const httpGetFolders = async () => {
    const response = await axios.get("favorite-folders");
    return response.data;
};

// Create a new folder
export const httpSendFolder = async (folders: { folderName: string }) => {
    const response = await axios.post("favorite-folders", folders);
    return response.data;
};

// Update an existing folder
export const httpUpdateFolder = async (folder: {
    id: number;
    folderName: string;
}) => {
    const response = await axios.patch(`favorite-folders/${folder.id}`, {
        folderName: folder.folderName,
    });
    return response.data;
};

// Delete a folder
export const httpDeleteFolder = async (id: number) => {
    const response = await axios.delete(`favorite-folders/${id}`);
    return response.data;
};