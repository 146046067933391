// selectors.ts
import { RootState } from "../../utils/types";

export const favoriteFoldersCompanySelector = (state: RootState) => ({
  status: state.favoriteFoldersCompany.status,
  message: state.favoriteFoldersCompany.message,
  companies: state.favoriteFoldersCompany.companies,
});

// export const favoriteFoldersCompanySelector = (state: RootState) =>
//   state.favoriteFoldersCompany.map

