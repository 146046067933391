import { useDispatch, useSelector } from "react-redux";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { logout } from "../../redux/modules/auth/actions";
import { thirdPartyUserSelector } from "../../redux/modules/thirdPartyUser/selectors";
import NotificationToast from "../NotificationToast";
import { RootState } from "../../redux/utils/types";
import { useCallback, useState } from "react";
import { createFolder, deleteFolder, updateFolder } from "../../redux/modules/favorites/action";
import FavoritesModal from "../../pages/User/Favorites/FavoritesModal";
import FavoritesFolderDropdown from "../../pages/User/Favorites";
import { getFavoriteFoldersIdCompany, removeCompanyInFolder } from "../../redux/modules/favoritesCompany/action";

export default function Menu() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const thirdPartyUser = useSelector(thirdPartyUserSelector);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  // Access favorite folders from Redux store
  const { map: favoriteFoldersMap } = useSelector(
    (state: RootState) => state.favoriteFolders
  );

  // Convert Map to Array of FavoriteFolder
  const favoriteFolders = Array.from(favoriteFoldersMap.values());

  const [showModal, setShowModal] = useState(false);
  const [folderToEdit, setFolderToEdit] = useState<string | null>(null);
  const [notification, setNotification] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
  const [removingFolder, setRemovingFolder] = useState<string | null>(null);

  const handleShow = (folder: string | null = null) => {
    setFolderToEdit(folder);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const addFavoriteFolder = useCallback(
    async (folderName: string) => {
      if (folderName.trim()) {
        try {
          await dispatch(createFolder({ folderName })).unwrap();
          setNotification({ show: true, message: 'Folder created successfully' });
          setTimeout(() => setNotification({ show: false, message: '' }), 3000);
        } catch (error) {
          console.error("Failed to create folder:", error);
          setNotification({ show: true, message: 'Failed to create folder' });
          setTimeout(() => setNotification({ show: false, message: '' }), 3000);
        }
      }
    },
    [dispatch]
  );

  const editFolderName = useCallback(
    async (oldFolderName: string, newFolderName: string) => {
      if (newFolderName.trim()) {
        try {
          const folder = favoriteFolders.find(
            (folder) => folder.folderName === oldFolderName
          );
          if (folder) {
            const folderId = folder.id;
            await dispatch(
              updateFolder({ id: folderId, folderName: newFolderName })
            ).unwrap();
            setNotification({ show: true, message: 'Folder updated successfully' });
            setTimeout(() => setNotification({ show: false, message: '' }), 3000);
          } else {
            console.error("Folder not found");
          }
        } catch (error) {
          console.error("Failed to update folder:", error);
          setNotification({ show: true, message: 'Failed to update folder' });
          setTimeout(() => setNotification({ show: false, message: '' }), 3000);
        }
      }
    },
    [dispatch, favoriteFolders]
  );

  const removeFavoriteFolder = useCallback(
    async (folderName: string) => {
      const folder = favoriteFolders.find((f) => f.folderName === folderName);

      if (folder) {
        setRemovingFolder(folderName); // Set the removing folder state
        try {
          // Fetch the companies associated with this folder
          const companies = await dispatch(getFavoriteFoldersIdCompany(folder.id)).unwrap();

          // Remove all companies from the folder
          for (const company of companies) {
            let companyId = company.companyId;
            let folderId = company.folderId;
            await dispatch(removeCompanyInFolder({
              folderId: folderId,
              companyId: companyId // Ensure you use the correct company ID
            })).unwrap();
          }

          // Remove the folder itself
          await dispatch(deleteFolder(folder.id)).unwrap();
          setNotification({ show: true, message: 'Folder removed successfully' });
          setRemovingFolder(null); // Clear the removing folder state
          setTimeout(() => setNotification({ show: false, message: '' }), 3000);
        } catch (error) {
          console.error("Failed to remove folder and its companies:", error);
          setNotification({ show: true, message: 'Failed to remove folder' });
          setRemovingFolder(null); // Clear the removing folder state
          setTimeout(() => setNotification({ show: false, message: '' }), 3000);
        }
      } else {
        console.error("Folder not found");
      }
    },
    [dispatch, favoriteFolders]
  );

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="vertical-nav bg-white" id="sidebar">
      <h1 className="logo">
          Wendwise
          <sup className="tm">TM</sup>
        </h1>
        <ul className="nav flex-column bg-white mb-0 px-0">
          <li className="nav-item">
            <NavLink
              to="home"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-home mr-3 fa-fw"></i>
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="requests"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-commenting-o mr-3 fa-fw"></i>
              My Requests
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="activity"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-line-chart mr-3 fa-fw"></i>
              Activity
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <NavLink
              to="flagged"
              className={({ isActive }) =>
                isActive
                  ? "nav-link text-white bg-primary"
                  : "nav-link text-dark"
              }
            >
              <i className="fa fa-flag-o mr-3 text-dark fa-fw"></i>Flagged
            </NavLink>
          </li>

          {/* Add Favorites */}
          <li className="nav-item">
            <a
              role="button"
              className="nav-link text-dark"
              onClick={() => handleShow(null)}
            >
              <i className="fa fa-plus-circle mr-3 fa-fw"></i>
              Add Favorites Folder
            </a>
          </li>

          {/* Favorites ModalComponent open */}
          <FavoritesModal
            show={showModal}
            handleClose={handleClose}
            addFavoriteFolder={addFavoriteFolder}
            editFolderName={editFolderName}
            folderToEdit={folderToEdit}
          />

          {/* Favorites Folder Dropdown */}
          <FavoritesFolderDropdown
            favoriteFolders={favoriteFolders}
            handleDragOver={handleDragOver}
            handleShow={handleShow}
            removeFavoriteFolder={removeFavoriteFolder}
          />

          <li className="nav-item">
            <a
              href="#pageSubmenu"
              data-toggle="collapse"
              aria-expanded="false"
              aria-controls="pageSubmenu"
              className="dropdown-toggle nav-link text-dark"
            >
              <i className="fa fa-cog mr-3 text-dark fa-fw"></i>Settings
            </a>
            <ul className="nav collapse" id="pageSubmenu">
            { 
              thirdPartyUser && 
              (
                <li className="nav-item">
                    <NavLink
                      to="settings"
                      className={({ isActive }) =>
                        isActive
                          ? "nav-link text-white bg-primary"
                          : "nav-link text-dark"
                      }
                    >
                      <i className="fa fa-user mr-3 text-dark fa-fw"></i>User
                      Settings
                    </NavLink>
                </li>
              )
            }
              <li className="nav-item">
                <NavLink
                  to="notifications"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link text-white bg-primary"
                      : "nav-link text-dark"
                  }
                >
                  <i className="fa fa-bell-o mr-3 text-dark fa-fw"></i>
                  Notifications
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  role="button"
                  className="nav-link text-dark"
                  onClick={handleLogout}
                >
                  <i className="fa fa-power-off mr-3 text-dark fa-fw"></i>Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Outlet />
      {notification.show && (
        <NotificationToast message={notification.message} show={notification.show} />
      )}
    </>
  );
}
