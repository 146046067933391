// slice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { favoriteFolders, RootState } from "../../utils/types";
import { createFolder, deleteFolder, getAllFavoriteFolders, updateFolder } from "./action";

const initialState: RootState["favoriteFolders"] = {
    status: "idle",
    message: "",
    map: new Map<string, favoriteFolders>(),
};

export const FavoriteFolders = createSlice({
    name: "folders",
    initialState,
    reducers: {
        setFolder: (state, action: PayloadAction<favoriteFolders>) => {
            state.map.set(action.payload.id.toString(), action.payload);
        },
        reset: (state) => {
            state.status = "idle";
            state.message = "";
        },
    },
    extraReducers: (builder) => {
        builder

            // Handle get folder
            .addCase(getAllFavoriteFolders.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getAllFavoriteFolders.fulfilled, (state, action) => {
                state.status = "success";
                state.map = new Map(
                    action.payload.map((folder: favoriteFolders) => [
                        folder.id.toString(),
                        folder,
                    ])
                );
                state.status = "idle";
            })

            // Handle create folder
            .addCase(createFolder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(createFolder.fulfilled, (state, action) => {
                state.status = "success";
                state.message = "Folder created Successfully";
                state.map.set(action.payload.id.toString(), action.payload);
            })
            .addCase(createFolder.rejected, (state) => {
                state.status = "failed";
                state.message = "Something went wrong";
            })

            // Handle delete folder
            .addCase(deleteFolder.pending, (state) => {
                state.status = "loading";
            })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.status = "success";
                state.message = "Folder removed Successfully";
                state.map.delete(String(action.payload.id));
            })
            .addCase(deleteFolder.rejected, (state) => {
                state.status = "failed";
                state.message = "Failed to remove folder";
            })

            // Handle update folder
            .addCase(updateFolder.pending, (state) => {
                state.status = "loading";
            })
            // the updateFolder action payload
            .addCase(updateFolder.fulfilled, (state, action) => {
                if (!action.payload || !action.payload.id) {
                    return;
                }
                state.status = "success";
                state.message = "Folder updated Successfully";
                state.map.set(action.payload.id.toString(), action.payload);
            })
            .addCase(updateFolder.rejected, (state) => {
                state.status = "failed";
                state.message = "Failed to update folder";
            });
    },
});

export default FavoriteFolders.reducer;
export const { setFolder, reset } = FavoriteFolders.actions;