import { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { RxCross2 as Cross } from "react-icons/rx";
import { getAllFavoriteFolders } from "../../../redux/modules/favorites/action";

export default function FavoritesModal(props: {
    show: boolean;
    handleClose: () => void;
    addFavoriteFolder: (folderName: string) => void;
    editFolderName: (oldName: string, newName: string) => void;
    folderToEdit: string | null;
}) {
    const { show, handleClose, addFavoriteFolder, editFolderName, folderToEdit } =
        props;
    const [folderName, setFolderName] = useState("");

    const dispatch = useDispatch<any>();

    useEffect(() => {
        if (folderToEdit) {
            setFolderName(folderToEdit);
        } else {
            setFolderName("");
        }
    }, [folderToEdit]);

    const handleSave = async () => {
        if (folderToEdit) {
            await editFolderName(folderToEdit, folderName);
        } else {
            await addFavoriteFolder(folderName);
        }
        dispatch(getAllFavoriteFolders()); // Re-fetch folders
        handleClose();
    };

    const handleFocus = () => {
        // Clear the input field when it gains focus
        if (!folderToEdit) {
            setFolderName("");
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            {/* <Modal.Header closeButton>
        <Modal.Title>{folderToEdit ? "Edit Folder" : "Add Folder"}</Modal.Title>
      </Modal.Header> */}
            <Modal.Header>
                <Modal.Title>{folderToEdit ? "Edit Folder" : "Add Folder"}</Modal.Title>
                <Cross
                    className="text-danger h5 ml-2"
                    role="button"
                    onClick={handleClose}
                    title="Close"
                />
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formFolderName">
                        <Form.Label>Folder Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter folder name"
                            value={folderName}
                            onChange={(e) => setFolderName(e.target.value)}
                            onFocus={handleFocus} // Call handleFocus when input gains focus
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                    Save
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}